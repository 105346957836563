<template>
  <div class="message-container d-flex flex-column justify-content-center p-5 ">
    <h3 class="title mb-4"> <b v-text="title"></b></h3>
    <p class="message mb-5 text-muted" v-text="message"></p>
    <div class="w-50">
      <slot>
      </slot>
    </div>

  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      message: {
        type: String,
      },
    }
  }
</script>
<style lang="scss" scoped>
  .message-container {
    height: 500px;
    background-image: url('../../assets/images/shopify/no-data.jpg');
    background-position: center;
    background-size: cover;

    .title {
      font-size: 2.5rem;
    }

    .message {
      width: 50%;
      font-size: 2rem;
    }
  }
</style>